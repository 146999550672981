<template>
<p>signoutpage</p>
</template>

<script>
const config = require(`../../config`)
export default {
  name: 'Signout',
  data(){
    return {
    }
  },
  created() {
      console.log("signout")
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('profile')
      localStorage.removeItem('router')
      window.location.replace(`https://login.microsoftonline.com/${config.auth.tenantId}/oauth2/v2.0/logout`)
  },
}
</script>
